import React from "react"
import loadable from "@loadable/component"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "../components/card"
import EncloseFloats from "../components/enclose-floats"
import { EntryTitle, EntryHeader } from "../components/entry"
import useScriptedClient from "../utils/useScriptedClient"

const FeedbackForm = loadable(() => import("../components/feedback-form"))

const WebsiteFeedback = () => {
  const isScriptedClient = useScriptedClient()
  let form
  if (isScriptedClient) {
    form = <FeedbackForm />
  } else {
    form = <p>This feedback form requires JavaScript to work.</p>
  }

  return (
    <Layout>
      <SEO
        title="Website Feedback"
        description="If you have any suggestions, comments, or other feedback on the Vancouver-Anime.com website feel free to submit them here."
      />
      <Card variant="primary">
        <EncloseFloats>
          <EntryHeader>
            <EntryTitle>Website Feedback</EntryTitle>
          </EntryHeader>
          <p>
            If you have any suggestions, comments, or other feedback on the
            Vancouver-Anime.com website feel free to submit them here.
          </p>

          {form}
        </EncloseFloats>
      </Card>
    </Layout>
  )
}

export default WebsiteFeedback
