import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { math } from "polished"
import Img from "gatsby-image"
import v from "../styles/vars"
import EncloseFloats from "./enclose-floats"
import { CardBase } from "./card"
import header from "../utils/header"
import { em, px } from "../styles/units"
import breakpoints from "../styles/breakpoints"

const lineHeight = 1.168
export const EntryHeader = styled.header`
  line-height: ${lineHeight};
  margin-bottom: ${math(`${lineHeight} / ${v.textBeta}`)};
`
export const EntryTitle = styled(header(1))`
  margin-top: 0;
  margin-bottom: 0;
`
export const EntryCardTitle = styled(header(2))`
  margin-top: 0;
  margin-bottom: 0;
`

export const EntryImgFloat = styled.div`
  @media (min-width: 600px) {
    width: 250px;
    max-width: 100%;
    float: right;
    margin-left: 8px;
  }
`
export const EntryImgHeader = styled.div`
  margin-bottom: 16px;
`

export const EntryImg = styled(Img)``

export const EntryContent = styled.div`
  p {
    margin: ${em(v.lineHeight)} 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
`

export const EntryCard = styled(CardBase)`
  margin-bottom: ${px(v.gap)};

  ${breakpoints.downHorizontalNavigation} {
    margin-bottom: ${px(v.mobileGap)};
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`

const Entry = ({
  articleProps,
  title,
  entryMeta,
  children,
  footer,
  ...cardProps
}) => {
  return (
    <EntryCard {...cardProps} variant="primary">
      <article {...articleProps}>
        <EncloseFloats>
          <EntryHeader>
            <EntryTitle>{title}</EntryTitle>
            {entryMeta}
          </EntryHeader>
          {children}
        </EncloseFloats>
        {footer}
      </article>
    </EntryCard>
  )
}
Entry.propTypes = {
  articleProps: PropTypes.object,
  title: PropTypes.string,
  entryMeta: PropTypes.any,
  children: PropTypes.any,
  footer: PropTypes.any,
}

export default Entry
